<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Profil User">
          <div class="w-100 text-center" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <template v-else>
            <b-row class="relative">
              <b-col cols="12" class="mb-4">
                <div class="d-flex justify-content-center">
                  <img
                    :src="
                      detailUser.avatar
                        ? detailUser.avatar
                        : require(`@/assets/images/avatars/null-profile.webp`)
                    "
                    @error="
                      $event.target.src = require(`@/assets/images/avatars/no-image.png`)
                    "
                    :alt="detailUser.name"
                    class="rounded-pill"
                    style="height: 150px; width: 150px; object-fit: cover"
                  />
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Nama Mentor</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">{{ detailUser.name }}</p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Email</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">{{ detailUser.email }}</p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Phone Number</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">{{ detailUser.phone_number }}</p>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Tanggal Lahir</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{
                      detailUser.biodata && detailUser.biodata.birth_date
                        ? detailUser.biodata.birth_date
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Profesi</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{
                      detailUser.biodata && detailUser.biodata.profession
                        ? detailUser.biodata.profession
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Bio</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{
                      detailUser.biodata && detailUser.biodata.bio
                        ? detailUser.biodata.bio
                        : "-"
                    }}
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div
                  class="d-flex align-items-start flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">About</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{
                      detailUser.user_information &&
                      detailUser.user_information.about
                        ? detailUser.user_information.about
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between align-self-lg-start w-50"
                  >
                    <h5 class="mb-0">Projects</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <div class="w-100">
                    <ul
                      class="p-0"
                      v-if="
                        detailUser.user_information &&
                        detailUser.user_information.projects
                      "
                    >
                      <li
                        v-for="(projects, index) in detailUser.user_information
                          .projects"
                        :key="index"
                      >
                        {{ projects }}
                      </li>
                    </ul>
                    <div v-else>-</div>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between align-self-lg-start w-50"
                  >
                    <h5 class="mb-0">Social Media</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <div class="w-100">
                    <ul
                      class="p-0"
                      v-if="
                        detailUser.user_information &&
                        detailUser.user_information.social_media
                      "
                    >
                      <li
                        v-for="socialMedia in detailUser.user_information
                          .social_media"
                        :key="socialMedia.platform"
                      >
                        <p class="mb-0 text-capitalize">
                          {{ socialMedia.platform }}
                        </p>
                        <a
                          :href="socialMedia.url"
                          target="_blank"
                          class="mb-0"
                          >{{ socialMedia.url }}</a
                        >
                      </li>
                    </ul>
                    <div v-else>-</div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between align-self-lg-start w-50"
                  >
                    <h5 class="mb-0">Awards</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <div class="w-100">
                    <ul
                      class="p-0"
                      v-if="
                        detailUser.user_information &&
                        detailUser.user_information.awards
                      "
                    >
                      <li
                        v-for="(awards, index) in detailUser.user_information
                          .awards"
                        :key="index"
                      >
                        {{ awards }}
                      </li>
                    </ul>
                    <div v-else>-</div>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" class="mt-2">
                <h4>Galleries</h4>
                <b-row
                  v-if="
                    detailUser.user_information &&
                    detailUser.user_information.gallery
                  "
                >
                  <b-col
                    cols="12"
                    md="4"
                    v-for="(galleries, index) in detailUser.user_information
                      .gallery"
                    :key="index"
                  >
                    <img
                      :src="galleries"
                      :alt="index"
                      class="image_galleries"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <b-card title="Topic List">
      <b-button
        @click="cleanUpForm()"
        v-b-modal.form-topic
        variant="primary"
        class="btn waves-effect waves-float waves-light btn-primary mb-2"
      >
        Create Topic
      </b-button>
      <TableTopic
        :topicData="topicData"
        :is-loading="isLoading"
        :get-data="getData"
        :formPayload="formPayload"
        :edit-item="editItem"
        :edit-id="editId"
        :create-item="createItem"
        :deleteItem="deleteItem"
        :update-item="updateItem"
        :validations="validations"
      />
    </b-card>
    <b-col cols="12">
      <a
        @click="handleBackButton()"
        variant="secondary"
        class="btn waves-effect waves-float waves-light btn-secondary"
      >
        Kembali
      </a>
    </b-col>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import TableTopic from "@/components/users/TableTopic.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BButton,
  BRow,
  BSpinner,
  BCol,
  BFormFile,
  BFormCheckbox,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    vSelect,
    BButton,
    BBadge,
    TableTopic,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      detailUser: {},
      topicData: [],
      formPayload: Object,
      isLoading: false,
      validations: "",
      editId: null,
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  created() {
    this.loadDetail();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    loadDetail() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/users/" + params)
        .then((response) => {
          this.detailUser = response.data.data;
          this.getData();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getData() {
      let slug = this.$route.params.uuid;
      this.isLoading = true;
      this.$http
        .get(`/api/v1/admin/mentorship/topic/${slug}`)
        .then((response) => {
          this.topicData = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        title: "",
        mentorship_duration: "",
      };
      this.validations = "";
    },
    editItem(item) {
      (this.editId = item.uuid),
        (this.formPayload = {
          title: item.title,
          mentorship_duration: item.mentorship_duration,
        });
      this.$bvModal.show("form-topic");
    },
    updateItem() {
      this.isLoading = true;
      let idMentor = this.$route.params.uuid;
      this.$http
        .post(
          `/api/v1/admin/mentorship/topic/${idMentor}/${this.editId}`,
          this.formPayload
        )
        .then((response) => {
          this.$bvModal.hide("form-topic");
          this.getData();
          successNotification(this, "Success", "Topic successfully updated!");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.messages
          ) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.validations
          ) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Error",
              "Form Tidak Boleh ada yang kosong"
            );
          }
          this.isLoading = false;
        });
    },
    createItem() {
      this.isLoading = true;
      let idMentor = this.$route.params.uuid;
      this.$http
        .post(`/api/v1/admin/mentorship/topic/${idMentor}`, this.formPayload)
        .then((response) => {
          this.$bvModal.hide("form-topic");
          this.getData();
          successNotification(this, "Success", "Topic successfully created!");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.messages
          ) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.validations
          ) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Error",
              "Form Tidak Boleh ada yang kosong"
            );
          }
          this.isLoading = false;
        });
    },
    deleteItem(id) {
      let idMentor = this.$route.params.uuid;
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Topic?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/mentorship/topic/${idMentor}/${id}`)
            .then((response) => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Topic successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.image_galleries {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 30px;
}
</style>
