<template>
  <div>
    <b-table
      striped
      hover
      :items="topicDataList"
      :fields="fields"
      :busy="isLoadingComponent"
      sort-by="no"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>
          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>
          <b-dropdown-item
            :to="
              checkPermission('adminmaster')
                ? `/schedule/${item.uuid}`
                : `/schedule-mentor/${item.uuid}`
            "
          >
            <feather-icon icon="ArchiveIcon" class="mr-75" />
            Schedule
          </b-dropdown-item>
          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-topic"
      centered
      :title="editIdModal !== null ? 'Edit Topic' : 'Add Topic'"
      size="lg"
      no-close-on-backdrop
    >
      <ValidationObserver>
        <div class="form">
          <div class="row mb-2">
            <b-col cols="12">
              <div class="form-group">
                <label for="title">Title:</label>
                <validation-provider
                  name="title"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    id="title"
                    type="text"
                    class="form-control"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nama Title"
                    v-model="formPayloadTopic.title"
                  />
                  <small
                    v-for="(validation, index) in validationsForm.title"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="form-group">
                <label for="mentor">Mentorship Duration:</label>
                <validation-provider
                  name="mentor"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    id="mentor"
                    type="number"
                    class="form-control"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Durasi Mentor"
                    v-model="formPayloadTopic.mentorship_duration"
                  />
                  <small
                    v-for="(
                      validation, index
                    ) in validationsForm.mentorship_duration"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
          </div>
        </div>
      </ValidationObserver>
      <template #modal-footer="{}">
        <b-button
          variant="primary"
          @click="editIdModal !== null ? updateItem() : createItem()"
          v-bind:disabled="isLoading"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { TrashIcon } from "vue-feather-icons";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BCol,
  BRow,
  BFormFile,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
import { checkPermission } from "@/auth/utils";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BCol,
    BRow,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    TrashIcon,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup(props) {
    return {
      checkPermission,
    };
  },
  props: {
    topicData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    validations: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    removeItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    topicData(value) {
      this.topicDataList = value;
    },
    formPayload(value) {
      this.formPayloadTopic = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
  },
  data() {
    return {
      required,
      isLoadingComponent: false,
      currentPageList: 1,
      resultData: {},
      topicDataList: [],
      formPayloadTopic: {
        title: "",
        mentorship_duration: "",
      },
      editIdModal: null,
      fields: [
        { key: "no" },
        { key: "title" },
        { key: "mentorship_duration", label: "Mentorship Duration (Menit)" },
        { key: "actions" },
      ],
      validationsForm: "",
    };
  },
  computed: {
    rows() {
      return this.topicDataList.length;
    },
  },

  methods: {},
};
</script>

<style></style>
