var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.topicDataList,"fields":_vm.fields,"busy":_vm.isLoadingComponent,"sort-by":"no","show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPageList - 1) * 10 + index + 1)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_vm._v(" Update ")],1),_c('b-dropdown-item',{attrs:{"to":_vm.checkPermission('adminmaster')
              ? ("/schedule/" + (item.uuid))
              : ("/schedule-mentor/" + (item.uuid))}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"ArchiveIcon"}}),_vm._v(" Schedule ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(item.uuid)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"TrashIcon"}}),_vm._v(" Delete ")],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"form-topic","centered":"","title":_vm.editIdModal !== null ? 'Edit Topic' : 'Add Topic',"size":"lg","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){_vm.editIdModal !== null ? _vm.updateItem() : _vm.createItem()}}},[_vm._v(" Save ")])]}}])},[_c('ValidationObserver',[_c('div',{staticClass:"form"},[_c('div',{staticClass:"row mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title:")]),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayloadTopic.title),expression:"formPayloadTopic.title"}],staticClass:"form-control",class:classes,attrs:{"id":"title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nama Title"},domProps:{"value":(_vm.formPayloadTopic.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayloadTopic, "title", $event.target.value)}}}),_vm._l((_vm.validationsForm.title),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"mentor"}},[_vm._v("Mentorship Duration:")]),_c('validation-provider',{attrs:{"name":"mentor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayloadTopic.mentorship_duration),expression:"formPayloadTopic.mentorship_duration"}],staticClass:"form-control",class:classes,attrs:{"id":"mentor","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Durasi Mentor"},domProps:{"value":(_vm.formPayloadTopic.mentorship_duration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayloadTopic, "mentorship_duration", $event.target.value)}}}),_vm._l((_vm.validationsForm.mentorship_duration),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }